export default {
    name: 'ConfigDcmr',
    path: '/config',
    component: () =>
        import ( /* webpackChunkName: "ConfigDcmr" */ '@/modules/config/layouts/ConfigDcmr.vue'),
    children: [{
            path: 'center',
            name: 'center',
            component: () =>
                import ( /* webpackChunkName: "config-center" */ '../views/CenterView.vue'),
        },
        {
            path: 'carrier',
            name: 'carrier',
            component: () =>
                import ( /* webpackChunkName: "config-carrier" */ '../views/CarrierView.vue'),
        },
        {
            path: 'destiny',
            name: 'destiny',
            component: () =>
                import ( /* webpackChunkName: "config-destiny" */ '../views/DestinyView.vue'),
        },
        {
            path: 'ordertype',
            name: 'ordertype',
            component: () =>
                import ( /* webpackChunkName: "config-orderType" */ '../views/OrderType.vue'),
        },
        {
            path: 'trucktype',
            name: 'trucktype',
            component: () =>
                import ( /* webpackChunkName: "config-truckType" */ '../views/TruckType.vue'),
        },
        {
            path: 'invoice',
            name: 'invoice',
            component: () =>
                import ( /* webpackChunkName: "config-invoice" */ '../views/InvoiceView.vue'),
        },
        {
            path: 'receptions',
            name: 'receptions',
            component: () =>
                import ( /* webpackChunkName: "config-invoice" */ '../views/ReceptionsView.vue'),
        },
        {
            path: 'cmrCounter',
            name: 'cmrCounter',
            component: () =>
                import ( /* webpackChunkName: "config-invoice" */ '../views/CmrCounterView.vue'),
        },
        {
            path: 'fuel',
            name: 'fuel',
            component: () =>
                import ( /* webpackChunkName: "config-invoice" */ '../views/FuelView.vue'),
        },
    ]
}