import cmrApi from "@/api/CmrApi"
import Swal from 'sweetalert2'
import { i18n } from '@/main.js'
import { responseErrorText } from "@/helpers/Parser";

export const closeCmr = async (context, form) => {
    let raqInfo = form[1].data_raq
    Swal.fire({
        title: i18n.global.t('waitPlease'),
        allowOutsideClick: false,
        confirmButtonColor: "#414DBC",
        cancelButtonColor: "#6e7d88"
      });
      Swal.showLoading(); 
    const error = checkBody(form[0],raqInfo)
    if (error.length) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#6e7d88',
        })
        return
    }
    const path = `/api/v1/load`
    const response = cmrApi.post(path, form[0] ).then(getResponse => {
      return getResponse
    })
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while closing the CMR`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    });
    return response

}

export const postCmrdata = async (context, cmrData) => {

    const path = `/api/v1/cmrdata`
    const response = cmrApi.post(path, cmrData ).then(getResponse => {
      return getResponse
    })
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while closing the CMR`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    });

    return response

}

export const generateCmrPdf = async (context, cmrNumber) => {
    Swal.fire({
        title: i18n.global.t('waitPlease'),
        allowOutsideClick: false,
        confirmButtonColor: "#414DBC",
        cancelButtonColor: "#6e7d88"
    });
    Swal.showLoading();

    try {
        const path = `/api/v1/generate?cmrNumber=${cmrNumber}`;
        const response = await cmrApi.get(path, { responseType: 'blob' });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const fileName = `${cmrNumber}-${new Date().toISOString()}.pdf`;
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        Swal.close();

        return response.data;
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        });
        return null; 
    }
};

export const closeCmrManualy = async (context, reference) => {
    try {
        const path = `/api/v1/closeExpedition?reference=${reference}`
        const response = await cmrApi.post(path)
    
        return response
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
}

export const closeReturn = async (context, body) => {
    
    const error = []
    if (!body.collaborator) {
        error.push('Collaborator is empty')
    }
    if (body.retur.packagingAdded.length === 0) {
        error.push('Returns are empty')
    }


    const cmrProcess = body.cmr_process.find(c => c.process.name === 'RETURN')
    if (!cmrProcess) {
        error.push('Reference process is empty')
    }

    if (error.length) {
        return error
    }

    const bodyR = {
        reference: cmrProcess.reference,
        cmr_return: {
            collaborator: {
                name: body.collaborator
            },
            returns_destiny: body.retur.packagingAdded
        }
    }

    try {
        const path = '/api/v1/closeReturn'
        const response = await cmrApi.post(path, bodyR)
        return response
    } catch (err) {
        Swal.fire({
            icon: "error",
            title: `Error ${err.response.status}`,
            text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
}

const checkBody = (body,raqInfo) => {
    
    const error = []

    if (!body.cmr_load.collaborator.name) {
        error.push('Collaborator is empty')
    }
    if (!body.cmr_load.expedition) {
        error.push('expedition is empty')
    }
    if (body.cmr_load.expedition < 0) {
        error.push('Wrong expedition value')
    }
    if (!body.cmr_load.optimization) {
        error.push('optimization is empty')
    }
    if (!body.cmr_load.boxes) {
        error.push('boxes is empty')
    }
    if (body.cmr_load.boxes < 0) {
        error.push('Wrong boxes value')
    }
    if (!body.cmr_load.weight) {
        error.push('weight is empty')
    }
    if (body.cmr_load.weight < 0) {
        error.push('Wrong weight value')
    }
    if (body.cmr_load.volume === "") {
        error.push('volume is empty')
    }
    if (body.cmr_load.volume < 0) {
        error.push('Wrong volume value')
    }

    if (!body.cmr_load.returns_center.length) {
        error.push('return is empty')
    }
    if (!body.cmr_load.seals.length) {
        error.push('seal is empty')
    }

    if (raqInfo.length === 0 || raqInfo == null || raqInfo === null) {
        error.push('raq is empty')
    }

    return error
}


export const uploadFileInBucket = async (context, fileData) => {
    Swal.fire({
        title: i18n.global.t('waitPlease'),
        text: i18n.global.t('uploadingFileInBucket'),
        allowOutsideClick: false,
        confirmButtonColor: "#414DBC",
        cancelButtonColor: "#6e7d88"
    });
    Swal.showLoading();

    const path = `/api/v1/upload`;
    const formData = new FormData();
    formData.append('file', fileData.file); 
    formData.append('vada', fileData.vada); 
    formData.append('year', fileData.year); 
    formData.append('documentType', fileData.documentType); 
    return cmrApi.post(path, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then((response) => {
        if (response.status === 200) {
            Swal.close()
            return response.data;
        } else {
            Swal.fire({
                icon: 'error',
                title: `Error uploading file in bucket`,
                confirmButtonColor: '#414DBC',
                cancelButtonColor: '#6e7d88',
            });
            throw new Error(response.statusText);
        }
      
    })
    .catch((error) => {
        Swal.fire({
            icon: 'error',
            title: `Error ${error.response?.status || ''}`,
            text: responseErrorText(error, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        });
        throw error; 
    });
};
