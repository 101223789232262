import sha512 from 'js-sha512'


export const useFedid = async (config) => {
    const isPreprodAuthEnv = config.environment !== "production";
    if (config.code !== null) {
        let formData = new URLSearchParams('client_id=' + config.clientId + '&redirect_uri=' + window.location.origin + '/&code=' + config.code + '&grant_type=authorization_code&scope=openid profile');
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded"},
            body: formData
        };
        const response = await fetch("https://" + (isPreprodAuthEnv ? "preprod." : "") + "idpdecathlon.oxylane.com/as/token.oauth2", requestOptions)

        if (!response.ok) {
            window.location.replace("https://" + (isPreprodAuthEnv ? "preprod." : "") + "idpdecathlon.oxylane.com/as/authorization.oauth2?" + "client_id=" + config.clientId + "&redirect_uri=" + window.location.origin + "/&response_type=code" + "&scope=openid profile");
        }
        return response.json();
    } else {
        window.location.replace("https://" + (isPreprodAuthEnv ? "preprod." : "") + "idpdecathlon.oxylane.com/as/authorization.oauth2?" + "client_id=" + config.clientId + "&redirect_uri=" + window.location.origin + "/&response_type=code" + "&scope=openid profile");
    }
}

export const refreshToken = async (config) => {
    let tokenData = JSON.parse(localStorage.getItem("attributesToken"));
    const isPreprodAuthEnv = config.environment !== "production";
        let formData = new URLSearchParams('grant_type=refresh_token&refresh_token='+tokenData.refresh_token);
        const requestOptions = {
            method: "POST",
            headers: {"Cookie":"PF=pRFWFnFtikClK7Nu2Vrvdx","Content-Type":"application/x-www-form-urlencoded"},
            body: formData
        };
        const response = await fetch("https://" + (isPreprodAuthEnv ? "preprod." : "") + "idpdecathlon.oxylane.com/as/token.oauth2", requestOptions)

        if (!response.ok) {
            window.location.replace("https://" + (isPreprodAuthEnv ? "preprod." : "") + "idpdecathlon.oxylane.com/as/authorization.oauth2?" + "client_id=" + config.clientId + "&redirect_uri=" + window.location.origin + "/&response_type=code" + "&scope=openid profile");    
        }
            return response;
}

export const getProfile = async (config, token) => {

    const isPreprodAuthEnv = config.environment !== "production";
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "no-cache",
            "Authorization": "Bearer " + token.trim()
        }
    };
    const response = await fetch("https://" + (isPreprodAuthEnv ? "preprod." : "") +"idpdecathlon.oxylane.com/idp/userinfo.openid", requestOptions)
    if (!response.ok) {
        response.text().then(error => new Error(error));
        return {}
    }
    return response.json();
}
export const decodeToken = (config) => {
    let tokenFormat = config.tokenPartToDecode.split('.')[1];
    let tokenReplaceChars = tokenFormat.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(tokenReplaceChars).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    let jsonIdToken = JSON.parse(jsonPayload);
    return jsonIdToken
   
}
export const getExpDateToken = (config) => {
    let jsonAccessToken = decodeToken(config)
    return jsonAccessToken["exp"]
}

export const encrypt =  (localStorageParam,valueToEncrypt) => {
    let encriptedValue = null
    if(localStorageParam == "roles"){
    encriptedValue = sha512Encrypt(valueToEncrypt)
    }else{ 
    encriptedValue=  btoa(valueToEncrypt)    
    }
    localStorage.setItem(localStorageParam,encriptedValue)
}

const sha512Encrypt =  (str) =>{
   return sha512(str)

  }
export const decrypt =  (paramToDecrypt) => {
    //will add roles in env.preprod file instead of here
    let decryptedParamInBytes = null
    if(paramToDecrypt == "roles"){
    let paramToDecryptOfLocalStorage = localStorage.getItem(paramToDecrypt)
        if(paramToDecryptOfLocalStorage == "56cac01ba8887568d34faec764f240bf679bd43b969b8292f61a189808c1ba602e2f3c47d2996f3fc77c945535085a574f43aaede6dccbb2151c104c1e941b5f"){
            decryptedParamInBytes = atob("Uk9MRV9TVVBFUl9BRE1JTg==")        }
        else if(paramToDecryptOfLocalStorage == "aa670c231ebf1090138742f3cb28e968510916bcc57de44e26350be2f4ffb8724edfdeab0dc4bfeacfcbe7aea2401796a0d89ebc8cf21ec5ba9c20e218d96c2f"){
            decryptedParamInBytes = atob("Uk9MRV9JVF9TVVBFUl9BRE1JTg==")
        }else if(paramToDecryptOfLocalStorage == "d0e2a4f6b4519604dfae457b74c5c44d4e2f2a28d02c992143019d8311395530387846efc295f35a42c7e084c3e26594e24f970ef8cf8a17825ff5b2e8d14c10"){
            decryptedParamInBytes = atob("Uk9MRV9BRE1JTg==")
        }else if(paramToDecryptOfLocalStorage == "ff424b933f5c7e6db8d20be16e9c93e15c54e5d7acc9da6808f76ea45fe7a758dc5381123954f9245d61d393e6d3dd1a3f5062aeb6331f239d4418c3c86f4a1b"){
            decryptedParamInBytes = atob("Uk9MRV9VU0VSX1ZBREE=")
        }else if(paramToDecryptOfLocalStorage == "87e8d65038ddc62c584d06a5b0f6bcebed21325a9b65785494efdd67b402e600d7b0d4b872411a3bb2f84cb7d9d089bec513a58996257877278251c781e9772c"){
            decryptedParamInBytes = atob("Uk9MRV9VU0VS")
        }else if(paramToDecryptOfLocalStorage == "0e8931a9140ff6b49e0505026bd621ad6e5a96d61cc9fe000a40699155f4ca455df3172c6d6ad11b07f69597ba7c301998a6a74b86713af6fdd952f352a60786"){
            decryptedParamInBytes = atob("Uk9MRV9VU0VSX1NUT1JF")

        }else if(paramToDecryptOfLocalStorage == "a036b2c15831ea3ad7ad0d6298a889c77991c086b39c05c84dbef89c4af93bf132062831316d765f2f3e77c36c177690c96132bb0e12a479e878476e1ba1f5bf"){
            decryptedParamInBytes = atob("Tk9fUk9MRQ==")
            
        }
    }else{
    let paramToDecryptOfLocalStorage = localStorage.getItem(paramToDecrypt)
    decryptedParamInBytes=  atob(paramToDecryptOfLocalStorage)
    }
  
    return decryptedParamInBytes;

}
    