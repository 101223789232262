export default () => ({
    isLoading: true,
    centers: [],
    ordertypes: [],
    trucktypes: [],
    destinies: [],
    carriers: [],
    invoices: [],
    receptions: [],
    editMode: false,
    vada:'',
    cmrCounters: []
})